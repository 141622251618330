import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Embeds from "../components/embeds";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Content = styled.div`
  position: relative;
  width: calc(100% - 2rem);
  height: 100%;
  max-width: 600px;
  padding: 6rem 1rem;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;

  h4 {
    font-size: 1.2rem;
    margin-left: auto;
  }
`;

const NiceLink = styled(Link)`
  color: inherit;
  font-weight: 600;
  font-family: Kanit;
`;

const Hero = styled.div`
  position: relative;
  height: 65vh;
  background: white;
  width: 100%;
  /* overflow: hidden; */
`;
const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const HeroUnder = styled.div`
  position: absolute;
  width: 100vw;
  height: 30vh;
  bottom: 0;
  left: 0;
  background: white;
  clip-path: polygon(0% 101%, 101% 101%, 0% 0%);
  z-index: 10;
`;

const PillBox = styled.div`
  position: absolute;
  width: 100vw;
  height: 30vh;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid green; */
  z-index: 20;
`;

const ClientPill = styled.div`
  background: white;
  /* border: 4px solid black; */
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 300px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.4);
`;

const Overlay = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8rem;
`;

const Underlay = styled.div`
  padding: 2rem;
  background: black;
  color: white;
  width: 100%;

  p {
    margin: 0;
    padding: 0;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
`;

const BackgroundBase = ({ className, fluid }) => (
  <BackgroundImage
    Tag="div"
    className={className}
    fluid={fluid}
    backgroundColor={"#769AB4"}
  />
);

const Background = styled(BackgroundBase)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 4;
`;

const ExternalLinkBase = styled.a`
  color: #092e66;
`;

const ExternalLink = ({ to, children }) => (
  <ExternalLinkBase href={to} target="_blank">
    {children}
  </ExternalLinkBase>
);

const BackLink = styled(Link)`
  color: #444;
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const LinkBox = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`;

const Template = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        homepage,
        byline,
        logoDescriptor,
        logoImage,
        backgroundImage,
      },
      html,
    },
  } = data;
  const pluralizedTitle = `${title}'${title.slice(-1) === "s" ? "" : "s"}`;
  return (
    <Layout>
      <SEO title={title} />
      <Embeds />
      <Container>
        <Hero>
          <Background fluid={backgroundImage.childImageSharp.fluid} />
          <GradientOverlay />
          <HeroUnder />
          <PillBox>
            <ClientPill>
              <Overlay>
                <Img
                  fluid={logoImage.childImageSharp.fluid}
                  style={{ width: "100%", height: "100%" }}
                  imgStyle={{ objectFit: "contain" }}
                />
              </Overlay>
              <Underlay>
                <p>{logoDescriptor}</p>
              </Underlay>
            </ClientPill>
          </PillBox>
        </Hero>
        <Content>
          <LinkBox>
            <BackLink to="/clients/">← All clients</BackLink>
            <h4>Client Profile</h4>
          </LinkBox>
          <h1>{title}</h1>
          <h2>{byline}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <p>
            <i>
              You can view{" "}
              <a href={homepage} target="_blank" rel="noopener noreferrer">
                {pluralizedTitle} website here.
              </a>
            </i>
          </p>
        </Content>
      </Container>
    </Layout>
  );
};

export default Template;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        homepage
        byline
        logoDescriptor
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logoImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
